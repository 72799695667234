import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'qrcard',
    loadChildren: () => import('./qrcard/qrcard.module').then( m => m.QrcardPageModule)
  },
  {
    path: 'add/:element',
    loadChildren: () => import('./add/add.module').then( m => m.AddPageModule)
  }, 
  {
    path: 'add/:element/:type',
    loadChildren: () => import('./add/add.module').then( m => m.AddPageModule)
  },

 
];
@NgModule({
  imports: [
    Ng2SearchPipeModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
